import logo from './logo.svg';
import logo2 from './833.gif'
import './App.css';
import { Component } from 'react';
import createModule from "./simple.mjs"; // eslint-disable-line
import libLouis from './libLouisReact';

function  braille_info (fname, desc, lang, region, braille) {
  this.fname =fname;
  this.desc = desc;
  this.lang = lang;
  this.region = region;
  this.braille = braille;
}

function wrap_getstr(module)
{
  return (() => {
        
    let result = module.ccall ("get_str", "number", [], 
                            []
                             );
    
    let str = module.UTF32ToString (result, 8192);
    
    module._free(result);
    return (str);
  });
}
function wrap_setstr(module)
{
  return ((str) => {
    let len = module.lengthBytesUTF32 (str);
    let ptr = module._malloc ((len + 1) * 4);
    
    module.stringToUTF32(str, ptr, (len + 1)*4);
    
    let result = module.ccall ("set_str", "number", ["number", "number"], 
                            [ptr, len]
                            );
    module._free (ptr);                            
    return (result);
  });
}

function wrap_test_file(module)
{
  return (() => {
        
    let result = module.ccall ("test_file", "number", [], 
                            []
                            );
    
    let str = module.AsciiToString (result);
    
    module._free(result);
    return (str);
  });
}

function wrap_translate(module)
{
  return ((str) => {
    let len = str.length;
    let ptr = module._malloc ((len + 4) * 4);
    
    module.stringToUTF32(str, ptr, (len + 4) * 4);
    
    let result = module.ccall ("test_translate_fr", "number", ["number", "number"], 
                            [ptr, len+1]
                            );
    module._free (ptr);                            
    let error = module.ccall ("test_lasterror", "number", [], 
                            []
                            );
    let rstr = "";
    
    if (error === 1)
    {
      rstr=module.UTF32ToString (result, 65536);
    }
    else
      rstr ="Error while translating " + error.toString()  
    
    module._free(result);
    return (rstr);
  });
}

class App extends Component
{
  constructor (props)
  {
    super(props);
    this.state = {
      fadd:null,
      ftranslate:null,
      ftestfile:null,
      fsetstr:null,
      fgetstr:null,
      valueA:0,
      valueB:0,
      result:0,
      charsize:0,
      textA:"Hello the world !",
      textB:"",
      textfile:"",
      textBraille:"",
      braillecnt:0,
      brailletable:[],
      brailletableselected:0,
      texttotranscript:"",
      texttranscripted:"",
    }

    this.liblouis = new libLouis ()

    this.handleChangeA = this.handleChangeA.bind(this);
    this.handleChangeB = this.handleChangeB.bind(this);

    this.handleChangeTextA = this.handleChangeTextA.bind(this);
    this.handleChangeTextB = this.handleChangeTextB.bind(this);
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitText = this.handleSubmitText.bind (this);
    this.handleSubmitTranscript = this.handleSubmitTranscript.bind(this);
    this.handleChangeTextTranscript = this.handleChangeTextTranscript.bind(this);
    this.handleChangeBrailleTable = this.handleChangeBrailleTable.bind(this);
    this.tick = this.tick.bind(this);
  }
  componentDidMount ()
  {
    
    createModule().then((Module) => {
      // need to use callback form (() => function) to ensure that `add` is set to the function
      // if you use setX(myModule.cwrap(...)) then React will try to set newX = myModule.cwrap(currentX), which is wrong
      console.log ("module created");
      console.log ("module test");
      
      this.liblouis.init (Module);
      
      if (this.liblouis.isInit())
      {
        let nb = this.liblouis.get_table_nbr();
        let brtable = [];
        for (let i = 0; i < nb; i++)
        {
          let description = this.liblouis.get_table_description(i);
          let br = new braille_info(
              this.liblouis.get_table_fname(i), 
              description,
              this.liblouis.get_table_lang(i), 
              this.liblouis.get_table_region(i),
              this.liblouis.unicode_translate_string(description, i)
              );
            brtable.push (
                br
              );
            this.liblouis.lou_free();
        }
        if (nb > 70)
          this.setState({brailletableselected:70});
        this.setState({brailletable:brtable});
        this.setState({fadd:(Module.cwrap("test_add", "number", ["number", "number"]))});
        this.setState({fsetstr:(wrap_setstr(Module))});
        this.setState({fgetstr:(wrap_getstr(Module))});
        this.setState({ftestfile:(wrap_test_file(Module))})
        this.setState({ftranslate:(wrap_translate(Module))})

        let data = ((x,y)=>{return x+y})
        console.log (data(5,6));
        console.log ("module test2");
        let data2 = (Module.cwrap("test_add", "number", ["number", "number"]));
        console.log ("cwrap2" + data2(5,6).toString());
        
      }
      //this.setState({fadd:((x,y) => {return x+y})});
      //this.countdown = setInterval(this.tick, 50);

    }).catch ((error)=> {
      console.log(error);
    });
    
  }
  
  handleSubmitTranscript (event)
  {
    event.preventDefault();
    if (this.liblouis.isInit())
    {
      let transcript = this.liblouis.unicode_translate_string(this.state.texttotranscript, this.state.brailletableselected);
      
      this.setState ({texttranscripted:transcript})
      let transcripthex = "";
      for (let i = 0; i < transcript.length; i++)
      {
        transcripthex += "0x" + transcript.charCodeAt(i).toString(16) + " ";
      }
      this.setState({texthex:transcripthex})
    }
  }
  componentWillUnmount ()
  {
    clearInterval(this.countdown);
  }

  tick ()
  {
    let a = this.state.valueA;
    let b = this.state.valueB;
    
    this.setState ({valueA:a + 1});
    this.setState ({valueB:b + 1});

    if (this.state.fadd != null)
    {
      let r = this.state.fadd(a, b);
      
      this.setState({result:r})
      
    }
    if (this.state.ftranslate != null)
    {
      this.setState({textBraille:this.state.ftranslate(this.state.textA)})  
      this.setState({braillecnt:this.state.braillecnt+1});
    }
    
  }
  
  makecall ()
  {
    if (this.state.fadd === null ||
      this.state.brailletable.length === 0)
      return (
      <>
      <img src={logo2} alt="loading" />
      <h1 className='loadingtitle'>Loading</h1>
      
      </>
      );
    
  }

  handleChangeBrailleTable(event) {
    this.setState({brailletableselected: event.target.value});
  }
  DisplayLouisTranscript ()
  {
    if (! this.liblouis.isInit())
      return (<p></p>)
    else
    {
      const brailleoption = this.state.brailletable.map ((item, idx)=> 
              <option value={idx}>{item.lang + " - " + item.desc}</option>
      );
      return (
        <>
        <form onSubmit={this.handleSubmitTranscript } className="formbraille">
            <label for="selectrans">
              Choisissez un standard Braille
              </label>
              <select id="selecttrans" onChange={this.handleChangeBrailleTable} value={this.state.brailletableselected}>
                {brailleoption}
              </select>
            
            <br/>
            <label for="intext">
              Saisissez un texte à transcrire
              </label>
                <textarea  id="intext" name="transb" value={this.state.texttotranscript} 
                  onChange={this.handleChangeTextTranscript}
                  rows={2}/>
            
            
                <input type="submit" value="Transcrire" />
          </form>
          <h1 className='divBrailleText brailletext'>{this.state.texttranscripted}</h1>
          
          </>
      );
    }
  }
  DisplayLouis ()
  {
    if (! this.liblouis.isInit())
      return (<h1 className='h1red'>Waiting</h1>)
    else 
    {
      console.log (this.state.brailletable);
        const renderList = this.state.brailletable.map((item, idx) => 
          <tr key={idx}>
          <td className='col1' >{idx}</td>
          <td className='col3' ><h3 >{item.desc}</h3><p>Fichier : {item.fname}</p><p>Code langue : {item.lang}</p><p >Région : {item.region}</p></td>
          <td className='col6' ><div className='div50 brailletext' ><p>{item.braille}</p></div></td>
          </tr>
        );
        return (
          <>
          <h1>Tables de transcriptions disponibles</h1>
          <table>
          <tbody>
            {renderList}
          </tbody>
          </table>
          </>
        );  
      }

  }
  handleSubmit(event) {
    
    event.preventDefault();
    if (this.state.fadd != null)
    {
      let r = this.state.fadd(this.state.valueA, this.state.valueB);
      this.setState({result:r})
    }

  }

  handleSubmitText(event) {
    
    event.preventDefault();
    
    this.setState ({charsize:this.state.fsetstr(this.state.textA)});
    this.setState({textB:this.state.fgetstr()});
    this.setState({textfile:this.state.ftestfile()})
    this.setState({textBraille:this.state.ftranslate(this.state.textA)})  
  }
  handleChangeTextTranscript(event) {this.setState({texttotranscript: event.target.value}); }
  handleChangeB(event) {    this.setState({valueB: event.target.value});  }
  handleChangeA(event) {    this.setState({valueA: event.target.value});  }
  handleChangeTextA(event) {    this.setState({textA: event.target.value});  }
  handleChangeTextB(event) {    this.setState({textB: event.target.value});  }
  
  render() {
    return (
      <div className="App">
        <header className="App-header">
        <h1>Test LibLouis</h1>
          {this.makecall()}
          

          
          
          
          {this.DisplayLouisTranscript ()}
          <p>{this.state.textBraille}</p>
          
          {this.DisplayLouis ()}
          

        </header>
      </div>
    );
  }
}
export default App;
